import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../layout"
import Navigation from "../navigation"
import MobileNavigation from "../navigationMobile"
import Img from "gatsby-image"
import mediaqueries from "../../styles/media"

interface Props {
  location?: any
  theme?: any
  title: string
}

const siteQuery = graphql`
  query pageQuery {
    mdx(frontmatter: { gatsbyTitle: { eq: "intro" } }) {
      id
      frontmatter {
        logo {
          id
          publicURL
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 1000
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

const IntroHero: React.FC<Props> = ({
  location,
  title = location.pathname,
}) => {
  const data = useStaticQuery(siteQuery)
  const { description, image, logo } = data.mdx.frontmatter

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <IntroHolder isOpen={isOpen}>
        <Layout>
          <Navigation location={location} />
          <TitleHolder>{title}</TitleHolder>
          <HeroHolder>
            <Img fluid={image.childImageSharp.fluid} alt={description} />
          </HeroHolder>
          <WhiteCircle />
        </Layout>
      </IntroHolder>
    </>
  )
}

const IntroHolder = styled.header`
  background: ${(p: any) => p.theme.colors.gradient};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-height: 365px;
  z-index: 99;
  margin-bottom: 200px;

  ${mediaqueries.desktop`
  padding-top: 150px;
`};
`

const HeroHolder = styled.div`
  position: absolute;
  right: 0;
  width: 538px;
  z-index: 1;
  bottom: -160px;
  right: 30px;

  ${mediaqueries.desktop`
  bottom: -261px;
  right: -33px;
  z-index: 1;
  max-width: 320px;
`};
`

const WhiteCircle = styled.div`
  position: absolute;
  bottom: 30px;
  right: 84px;
  width: 360px;
  height: 360px;
  border-radius: 100%;
  background: ${(p: any) => p.theme.colors.background};
  z-index: 0;

  ${mediaqueries.desktop`
  bottom: -165px;
  right: 0px;
  width: 220px;
  height: 220px;
`};
`

const TitleHolder = styled.h1`
  font-family: ${(p: any) => p.theme.fonts.exo};
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 122.19%;
  /* or 40px */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(p: any) => p.theme.colors.white};
  max-width: 600px;

  ${mediaqueries.desktop`
    max-width: 40vw;
    margin-bottom: 80px;
    margin-top: -50px;
`};
`

export default IntroHero
