import React, { useState } from "react"
import { Global } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import { globalStyles } from "../styles"
import Layout from "../components/layout"
import PageHero from "../components/pageHero"
import Register from "../components/register"
import Footer from "../components/footer"
import MDX from "../components/mdx"
import styled from "@emotion/styled"
import SEO from "../components/seo"

import MobileNavigation from "../components/navigationMobile"
import mediaqueries from "../styles/media"

interface Props {
  location: any
}

const siteQuery = graphql`
  query regulaminQuery {
    doc: mdx(frontmatter: { gatsbyTitle: { eq: "Regulations" } }) {
      id
      frontmatter {
        title
      }
      body
    }
  }
`

const DocPage: React.FC<Props> = ({ location }) => {
  const data = useStaticQuery(siteQuery)
  const [isOpen, setOpen] = useState(false)
  const { doc } = data
  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={`${doc.frontmatter.title}`} />
      <MobileNavigation isOpen={isOpen} setOpen={setOpen} location={location} />
      <PageHero title={doc.frontmatter.title} location={location} />
      <Main>
        <Layout>
          <DemoBodyHolder>
            <MDX content={doc.body} />
          </DemoBodyHolder>
        </Layout>
        <Register />
        <Footer />
      </Main>
    </>
  )
}

export default DocPage

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DemoBodyHolder = styled.div`
  margin-left: 10px;
  line-height: 25px;
  margin-top: -200px;
  margin-bottom: 100px;

  & strong {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & a {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & p {
    margin-top: 10px;
  }

  ${mediaqueries.desktop`
    margin-top: 0px;
`};
`

const StepsHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 200px;

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  margin-bottom: 100px;
`};
`
